.languages-card {
	background-color: #e9c3b8;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
}

.logo-grid-languages {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	padding-bottom: 30px;
	padding-left: 4vh;
	padding-right: 4vh;
	transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.technologies-card {
	background-color: #1a1a1a;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
}

.logo-grid-technologies {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	padding-bottom: 30px;
	padding-left: 4vh;
	padding-right: 4vh;
	transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.technologies-title {
	transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.logo {
	height: clamp(50px, 10vw, 70px);
	width: auto;
	aspect-ratio: 1/1;
	object-fit: contain;
	margin: 1vh;
}

.grid-item:hover .logo-grid-languages {
	/* Animate Card title and icons on hover */
	transform: translateY(6px);
}

.grid-item:hover .logo-grid-technologies {
	/* Animate Card title and icons on hover */
	transform: translateY(6px);
}
