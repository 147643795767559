/* CONTACTS CARD */

.contact-info {
	color: #ffffff;
	padding-top: 75px;
	padding-right: 55%;
	padding-left: 50px;
	background-color: #1f4954;
	text-align: left;
	position: relative;
	box-sizing: border-box;
}

@media (max-width: 800px) {
	.contact-info {
		padding-right: 50px;
	}
}

/* CONTACTS CARD TITLE */

.contact-card .grid-item-title {
	text-decoration: none;
	color: white;
}

.contact-card .grid-item-title:hover {
	text-decoration: underline;
}

.grid-item-title-left {
	margin-top: 0;
	max-width: 440px;
	margin-left: 0;
	margin-bottom: 50px;
	display: block;
	font-size: 55px;
}

@media (max-width: 640px) {
	.grid-item-title-left {
		font-size: 30px;
	}
}

/* CONTACTS CARD DIVIDER LINE */

.grid-item-divider-left {
	margin-left: 0;
	right: initial;
	transform: scaleX(1);
	word-wrap: break-word;
}

/* GRID ITEM TEXT */

.grid-item-text-left {
	max-width: 70%;
	margin: 0 0 2em;
	color: white;
	line-height: 27px;
}

/* CONTACT LINKS */

.contact-links {
	display: flex;
	align-items: center;
	padding-bottom: 55px;
}

.contact-link {
	padding: 4px;
	margin-right: 5px;
	display: inline-block;
	vertical-align: top;
}

.contact-icon {
	width: 30px;
	height: 30px;
	display: inline-block;
}

.github-icon {
	height: 40px;
	width: 40px;
}

/* CONTACT PICTURE */

.contact-picture {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	width: 50%;
	background-color: #e7e8ed;
}

@media (max-width: 800px) {
	.contact-picture {
		display: none;
	}
}

.contact-picture-img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}
