/* DEVELOPX CARD */

.developx-card {
	background-color: #224a54;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
}

.developx-apps-row {
	display: flex;
	justify-content: center;
	gap: 20px;
}

.tipsy-logo {
	height: 70px;
	border: 4px solid #306c7b;
	border-radius: 15px;
	transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.tipsy-logo:hover {
	transform: scale(1.05);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.pace-logo {
	height: 70px;
	border: 4px solid #306c7b;
	border-radius: 15px;
	transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.pace-logo:hover {
	transform: scale(1.05);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

/* ASA CARD */

.asa-card {
	background-color: rgb(247, 238, 210);
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
}

.asa-grid-text {
	margin-bottom: 60px;
}

/* URA CARD */

.ura-card {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
}

.ura-bg {
	background-image: url('../assets/ura-bg.png');
}

.ura-grid-text {
	margin-bottom: 60px;
}

/* FOODAROUND CARD */

.foodaround-card {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
}

.foodaround-bg {
	background-image: url('../assets/foodaround-bg.png');
}

.foodaround-grid-text {
	margin-bottom: 60px;
}
