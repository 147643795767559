@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900");

* {
	margin: 0;
	border: 0;
	box-sizing: border-box;
}

:root {
	/* background-color: rgb(253, 250, 244); */
	font-family: "Inter", sans-serif;
	--skillcard-size: 60vh;
}

.app {
	display: block;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.black-text {
	color: #000;
}

/* SECTION */

.section {
	width: 1420px;
	max-width: 100%;
	overflow: hidden;
	box-sizing: border-box;
	margin: 120px auto 0;
	padding: 40px;
}

@media (max-width: 768px) {
	.section {
		padding-top: 40px;
		padding-bottom: 40px;
		padding-left: 20px;
		padding-right: 20px;
	}
}

@media (max-width: 480px) {
	.section {
		padding-top: 40px;
		padding-bottom: 40px;
		padding-left: 10px;
		padding-right: 10px;
	}
}

.icon-button {
	cursor: pointer;
}

/* SECTION HEADER TITLE (SKILLS, WORK, etc) */

.section-header {
	font-family: Inter, Source Sans Pro, Helvetica Neue, Arial, sans-serif;
	font-size: 160px;
	font-weight: 800;
	letter-spacing: -0.02em;
	text-align: left;
	z-index: 10;
	position: relative;
	line-height: 0.6em;
	pointer-events: none;
	flex: 1 100%;
}

@media (max-width: 1024px) {
	.section-header {
		font-size: 136px;
	}
}

@media (max-width: 768px) {
	.section-header {
		font-size: 18vw;
	}
}

@media (max-width: 480px) {
	.section-header {
		font-size: 18vw;
	}
}

/* GRID ITEMS (INDIVIDUAL BOXES) */

.grid-items {
	display: flex;
	flex-flow: row wrap;
}

.grid-item {
	flex: 1 50%;
	padding: 60px 50px 30px;
	min-height: 55vh;
	box-sizing: border-box;
	position: relative;
	overflow: hidden;
	flex-direction: column;
	justify-content: center;
	display: flex;
	text-align: center;
	text-decoration: none;
	color: #fff;
}

@media (max-width: 900px) {
	.grid-item {
		padding-left: 35px;
		padding-right: 35px;
	}
}

@media (max-width: 1024px) {
	.grid-item,
	.grid-item:nth-last-child(1),
	.grid-item:nth-last-child(2) {
		flex: 1 100%;
		padding-left: 10px;
		padding-right: 10px;
	}
}

@media (max-width: 480px) {
	.grid-item {
		min-height: 45vh;
	}
}

/* TALL GRID ITEMS */

.grid-item-tall {
	padding-top: 100px;
	padding-bottom: 110px;
}

@media (max-width: 530px) {
	.grid-item-tall {
		padding: 60px 50px 30px;
	}
}

/* WIDE GRID ITEMS   */

.grid-item-wide {
	flex: 1 100%;
}

/* GRID ITEM BACKGROUND */

.grid-item-bg {
	transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
	background-size: cover;
	background-position: center center;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	/* background-position: 75% 50%; */
	z-index: 0;
}

.grid-item:hover .grid-item-bg {
	transform: scale3D(1.04, 1.04, 1);
}

/* DROPDOWN FLAP */

.flap {
	position: absolute;
	top: 0;
	right: 20px;
	padding: 17px 20px;
	background-color: #000;
	color: #fff;
	font-size: 13px;
	transform-origin: 0 0;
	will-change: transform, opacity;
	transform: scaleY(0) translateZ(0);
	transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
	font-family: Inter, Source Sans Pro, Helvetica Neue, Arial, sans-serif;
}

.grid-item:hover .flap {
	transform: scaleY(1) translateZ(0);
	transition: transform 0.35s cubic-bezier(0.19, 1, 0.22, 1);
}

.flap-label {
	transform: translateY(-15px);
	transition: transform 0.35s cubic-bezier(0.19, 1, 0.22, 1) 0.1s, opacity 0.35s cubic-bezier(0.19, 1, 0.22, 1) 0.1s;
	display: block;
	opacity: 0;
}

.grid-item:hover .flap-label {
	transform: translateY(0);
	opacity: 1;
}

/* CARD TITLE (Languages, Technologies, etc) */

.grid-item-title {
	font-size: 46px;
	line-height: 1.3em;
	margin: 45px auto 40px;
	transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	font-weight: 800;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	max-width: 680px;
	z-index: 10;
	letter-spacing: -0.3px;
}

@media (max-width: 768px) {
	.grid-item-title {
		font-size: 7vw;
	}
}

.grid-item:hover .grid-item-title {
	/* Animate Card title and icons on hover */
	transform: translateY(-6px);
}

/* CARD LOGO  */

.grid-item-logo {
	margin-bottom: 30px;
	transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	height: 70px;
	z-index: 1;
}

.grid-item:hover .grid-item-logo {
	/* Animate Card title and icons on hover */
	transform: translateY(6px);
}

/* GRID ITEM DIVIDER (LINE THING) */

.grid-item-divider {
	display: block;
	position: relative;
	height: 3px;
	background-color: currentColor;
	width: 60px;
	right: 0;
	margin: 60px auto 30px;
	transform: scaleX(0.8);
	transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.grid-item:hover .grid-item-divider {
	transform: scaleX(1) translate3D(0, 6px, 0);
}

/* CARD TEXT  */

.grid-item-text {
	margin: 0 auto 30px;
	width: 80%;
	z-index: 10;
	max-width: 500px;
	transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.grid-item:hover .grid-item-text {
	/* Animate Card title and icons on hover */
	transform: translateY(6px);
}
