.hero-section {
	height: 100%;
	min-height: 89vh;
	max-height: 90vh;
	position: relative;
	margin: 0;
	gap: 5vh;
	padding-top: calc(50px + 8vh);
	width: auto;
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: space-around;
}

.hero-tab {
	width: 80%;
	margin: 0 auto;
	box-sizing: border-box;
	transition: all 3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	overflow: visible;
}

/* BIG NAME  */
.hero-name {
	width: 100%;
	height: 100%;
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 5vw 0;
	box-sizing: border-box;
	font-weight: 800;
	font-size: calc(20px + 3.7vw);
	z-index: 1;
	animation: fadeIn 3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
}

.hero-ng {
	text-align: left;
	position: relative;
	left: -0.4em;
	overflow: visible;
	height: 1.2em;
	border: 2px solid transparent;
}

.hero-jun {
	position: relative;
	right: 10px;
	text-align: center;
	overflow: visible;
	height: 1.2em;
	border: 2px solid transparent;
}

@media (max-width: 480px) {
	.hero-jun {
		position: relative;
		right: 4vw;
	}
}

.hero-sheng {
	text-align: right;
	position: relative;
	right: -0.5em;
	overflow: visible;
	height: 1.2em;
	border: 2px solid transparent;
}

.hero-name-inner {
	display: inline-block;
	line-height: 1em;
	color: black;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

/* HERO SVG */

.tab-container {
	display: flex;
	flex-direction: column;
	height: auto;
	overflow: visible;
	height: 500%;
}

.tab {
	opacity: 0;
	width: 100%;
	height: auto;
	max-height: 70vh;
	fill: none;
	stroke: #d67a60;
	transition: all 5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	transition-delay: 1s;
}

.tab-outline {
	opacity: 0;
	position: absolute;
	width: 100%;
	height: auto;
	max-height: 70vh;
	top: 0;
	transition: all 5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	transition-delay: 1s;
}

/* .tab:nth-child(1) {
	transform: translateY(5px);
	opacity: 0.92;
} */

/* SHORT INTRO */
.portfolio-intro-header {
	padding-left: 20px;
	padding-right: 20px;
	font-weight: 400;
	font-size: clamp(10px, 3vw, 15px);
	text-align: center;
	animation: fadeAndTranslate 3s ease forwards;
}

@keyframes fadeAndTranslate {
	0% {
		opacity: 0;
		transform: translateY(-15px);
	}
	50% {
		opacity: 0;
		transform: translateY(-15px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
