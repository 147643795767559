/* UNIVUS CARD */

.univus-card {
	background-color: #d67a60;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
}

.univus-logo {
	height: 70px;
	border-radius: 15px;
	transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.univus-logo:hover {
	transform: scale(1.05);
}

/* WHATSAPP REDIRECTOR CARD */

.whatsapp-card {
	background-color: #3bab87;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
}

.whatsapp-grid-text {
	margin-bottom: 60px;
}

.whatsapp-redirect-logo {
	height: 70px;
	/* border: 4px solid #348b6f; */
	border-radius: 15px;
	transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.whatsapp-redirect-logo:hover {
	transform: scale(1.05);
	/* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); */
}

/* MAL CARD */

.mal-card {
	background-color: rgb(59, 101, 120);
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
}

.mal-grid-text {
	margin-bottom: 60px;
}
