.header {
	padding: 1rem;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #fefbf5;
}

.menu {
	/* position: absolute;
	top: 2.7vmin;
	left: 2.8vmin; */
	z-index: 2004;
	outline: none;
	-webkit-tap-highlight-color: transparent;
	width: 43px;
	padding: 10px;
	cursor: pointer;
	transition-duration: 200ms;
}

.menu:hover {
	width: 45px;
	padding: 10px 9px 10px 9px;
}

.button-clear {
	border: none;
	background-color: transparent;
}

.menu-icon-line {
	display: block;
	position: center;
	height: 2px;
	width: 100%;
	background-color: #a5acbc;
	margin-bottom: 4px;
	border-radius: 2px;
	transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.2s ease-out;
	transform: translateZ(0);
}

.menu-active > .menu-icon-line-1,
.menu-active:hover > .menu-icon-line-1 {
	transform: translateY(6px) rotateZ(-135deg);
	background-color: a5acbc;
}

.menu-active > .menu-icon-line-2,
.menu-active:hover > .menu-icon-line-2 {
	opacity: 0;
	background-color: #a5acbc;
}

.menu-active > .menu-icon-line-3,
.menu-active:hover > .menu-icon-line-3 {
	transform: translateY(-6px) rotateZ(135deg);
	background-color: #a5acbc;
}

.site-logo-container {
	/* position: absolute;
	top: 2vmin;
	right: 3vmin; */
	margin-right: 10px;
	margin-left: 10px;
	height: 32px;
	width: 32px;
	cursor: pointer;
}

.site-logo {
	height: 32px;
	width: 32px;
	justify-self: center;
	align-self: center;
}
